import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
		left: -100px;
	}
	
	::-webkit-scrollbar-track {
		background: #00C0FF;
		border-radius: 2px;
	}
	
	::-webkit-scrollbar-thumb {
		background-color: ${props => props.theme.darkBlue};
		border-radius: 2px;
		width: 10px;
	}

	* {
		scrollbar-color: ${props => props.theme.darkBlue} #D5E3F0;
		scrollbar-width: thin;
		box-sizing: border-box !important;
	}

	svg {
		user-select: none !important;
	}

	html {
		overflow-y: scroll;
		overflow-x: hidden;

		body {
			background-color: white;

			* {
				font-family: ${props => props.theme.rubik};
				font-size: 16px;
				line-height: 19px;
				color: #333;
				transition: 
				color .2s ease-in-out, 
				background-color .2s ease-in-out,
				opacity .2s ease-in-out,
				visibility .2s ease-in-out;
			}

			a {
				text-decoration: none;
				color: ${props => props.theme.darkBlue};

				&:hover {
					color: ${props => props.theme.lightBlue};
				}
			}

			.button {
					cursor: pointer;
					min-height: 30px;
					font-size: 14px;
					line-height: 17px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-transform: uppercase;
					border-radius: 5px;
					outline: none !important;
					padding: 0 20px;
					gap: 5px;
					user-select: none !important;

					&.noWrap {
						white-space: nowrap;
					}

					&.big {
						min-height: 40px;
					}

					&.height30 {
						font-size: 14px;
						line-height: 17px;
						min-height: 30px;
					}

					&.white {
						color: ${props => props.theme.darkBlue};
						border: 2px solid ${props => props.theme.darkBlue};
						background-color: white;

						&:hover {
							color: white;
							background-color: ${props => props.theme.darkBlue};
						}
					}

					&.blue {
						border: 2px solid ${props => props.theme.darkBlue};
						color: white;
						background-color: ${props => props.theme.darkBlue};

						&:hover {
							color: ${props => props.theme.darkBlue};
							background-color: white;
						}
					}

					&.transparent {
						border: 2px solid ${props => props.theme.darkBlue};
						color: ${props => props.theme.darkBlue};
						background-color: transparent;

						&:hover {
							color: white;
							background-color: ${props => props.theme.darkBlue};
						}
					}

					&.lightBlue {
						color: ${props => props.theme.darkBlue};
						background-color: #D3EFFF;

						&:hover {
							color: ${props => props.theme.lightBlue};
							/* background-color: white; */
						}
					}

					&.red {
						border: 2px solid #A2001D;
						background-color: #A2001D;
						color: white;
					}

					&.grad {
						background-color: #0270CB;
						background-image: linear-gradient(180deg, #22AFFE 0%, #0270CB 100%);
						background-size: 100% calc(100% + 10px);
						background-position: 0 0;
						transition: background-position .2s ease-in-out;
						color: white;
						border: none;

						&:hover {
							background-position: 0 -10px;
						}
					}

					&.orange {
						background-color: #0270CB;
						background-image: linear-gradient(180deg, #FEB446 0%, #FF6B00 100%);
						background-size: 100% calc(100% + 10px);
						background-position: 0 0;
						transition: background-position .2s ease-in-out;
						color: white;
						border: none;

						&:hover {
							background-position: 0 -10px;
						}
					}

					&.disabled {
						background-color: #D1D1D1 !important;
						background-image: none !important;
						cursor: not-allowed;
						outline: none;
						border: none;
					}
				}
				
			#root {
				width: 100vw;
				min-height: 100vh;
				display: flex;
				flex-direction: column;
				align-items: stretch;

				.container {
					width: calc(100vw - 40px);

					@media (min-width: 1280px) {
						width: calc(100vw - 80px);
						max-width: 1200px;
					}
				}

				h1 {
					font-weight: 500;
					font-size: 24px;
					line-height: 28px;
					/* color: ${props => props.theme.darkBlue}; */
				}

				h2 {
					font-weight: 500;
					font-size: 20px;
					line-height: 24px;
				}

				h3 {
					font-weight: 500;
					font-size: 16px;
					line-height: 19px;
				}

				p {
					font-family: ${props => props.theme.pt};
					font-weight: 400;
					font-size: 18px;
					line-height: 23px;

					b {
						font-family: ${props => props.theme.pt};
						font-weight: 700;
						font-size: 18px;
						line-height: 23px;
						color: ${props => props.theme.darkBlue};
					}
				}
			}
		}
	}
`;

export default GlobalStyles;