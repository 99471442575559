/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Burger from './Burger';
import Menu from './Menu';
import { useDevice } from 'hooks';

const Container = styled.div`
	height: 50px;
	padding: 0 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 3;
	/* overflow: hidden; */
	background-color: white;

	@media (min-width: 1280px) {
		padding: 0 40px;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 50px;
		left: 0;
		right: 0;
		height: 10px;
		background: linear-gradient(180deg, rgba(0, 70, 133, 0.2) 0%, rgba(0, 70, 133, 0) 100%);
	}
`;

const Logo = styled(Link)`
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;

	@media (min-width: 1280px) {
		font-size: 20px;
		line-height: 24px;		
	}
`;

const Header = () => {
	const { t } = useTranslation();
	const [ isOpen, setOpen ] = useState(false);
	const loc = useLocation();
	const { width } = useDevice();

	useEffect(() => setOpen(false), [loc.key, loc.pathname, window.innerWidth, width]);

	const onToggle = () => setOpen(prev => !prev);

	return (
		<Container>
			<Logo to='/'>
				{t('ACADEMSERVICE INTERNATIONAL')}
			</Logo>

			<Menu isOpen={isOpen} onToggle={onToggle} />
			<Burger onClick={onToggle} />
		</Container>
	);
};

export default Header;