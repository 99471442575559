import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { Lang, MainMenu } from './';

const crossIcon = <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M3.72939 3.70588C4.11885 3.31603 4.75029 3.31603 5.13975 3.70588L26.2951 24.8824C26.6845 25.2722 26.6845 25.9043 26.2951 26.2941V26.2941C25.9056 26.684 25.2742 26.684 24.8847 26.2941L3.72939 5.11765C3.33993 4.7278 3.33993 4.09573 3.72939 3.70588V3.70588Z" fill="#004685"/>
	<path d="M26.2704 3.70589C26.6599 4.09574 26.6599 4.72781 26.2704 5.11766L5.1151 26.2941C4.72564 26.684 4.09421 26.684 3.70475 26.2941V26.2941C3.31529 25.9043 3.31529 25.2722 3.70475 24.8824L24.8601 3.70589C25.2495 3.31604 25.881 3.31604 26.2704 3.70589V3.70589Z" fill="#004685"/>
</svg>;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	position: fixed;
	top: 0;
	right: -100vw;
	height: 100vh;
	width: 100vw;
	background-color: white;
	z-index: 2;
	padding: 20px;
	transition: right .2s ease-in-out;

	@media (min-width: 660px) {
		width: 320px;
	}

	@media (min-width: 1280px) {
		position: relative;
		width: auto;
		height: auto;
		flex-direction: row;
		align-items: center;
		top: auto;
		right: auto;
		padding: 0;
	}

	&.isOpen {
		right: 0;
	}
`;

const Line = styled.div`
	height: 0;
	border-top: 1px solid #D5E3F0;

	@media (min-width: 1280px) {
		display: none;
	}
`;

const Close = styled.div`
	width: 30px;
	height: 30px;
	position: absolute;
	top: 15px;
	right: 20px;
	
	@media (min-width: 1280px) {
		display: none;
	}
`;

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 70, 133, 0.8);
	opacity: 0;
	visibility: hidden;

	@media (min-width: 1280px) {
		display: none;
	}

	&.isOpen {
		opacity: 1;
		visibility: visible;
	}
`;

const Menu = ({ isOpen, onToggle }) => {
	return (
		<>
			<Container className={classnames({ isOpen })}>
				<Lang />
				<Line />
				<MainMenu />

				<Close onClick={onToggle}>
					{crossIcon}
				</Close>
			</Container>

			<Overlay className={classnames({ isOpen })} onClick={onToggle} />
		</>
	);
};

export default Menu;