import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	width: 30px;
	height: 30px;
	cursor: pointer;

	@media (min-width: 1280px) {
		display: none;
	}
`;

const BurgerImg = () => (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M27 30H3C1.3 30 0 28.7 0 27V3C0 1.3 1.3 0 3 0H27C28.7 0 30 1.3 30 3V27C30 28.7 28.7 30 27 30ZM3 2C2.4 2 2 2.4 2 3V27C2 27.6 2.4 28 3 28H27C27.6 28 28 27.6 28 27V3C28 2.4 27.6 2 27 2H3Z" fill="#004685"/>
	<path d="M5 21C5 20.4477 5.44772 20 6 20H24C24.5523 20 25 20.4477 25 21C25 21.5523 24.5523 22 24 22H6C5.44772 22 5 21.5523 5 21Z" fill="#004685"/>
	<path d="M5 15C5 14.4477 5.44772 14 6 14H24C24.5523 14 25 14.4477 25 15C25 15.5523 24.5523 16 24 16H6C5.44772 16 5 15.5523 5 15Z" fill="#004685"/>
	<path d="M5 9C5 8.44772 5.44772 8 6 8H24C24.5523 8 25 8.44772 25 9C25 9.55228 24.5523 10 24 10H6C5.44772 10 5 9.55228 5 9Z" fill="#004685"/>
</svg>);

const Burger = ({ onClick }) => (
	<Container onClick={onClick}>
		<BurgerImg />
	</Container>
);

export default Burger;